
export const websiteSEO = {
    home: {
        title: "Voice Up | Cours de chant tous niveaux à Genève",
        description: "Cours de chant à Genève, pose de voix et coach vocal pour amateur et professionnel, méthode Complete Vocal Technique.",
        canonHREF: "/"
    },
    program: {
        title: "Méthode | Voice Up",
        description: "Méthode et philosophie d’enseignement de la professeure de chant et coach vocal Tania Guillin.",
        canonHREF: "/methode"
    },
    about: {
        title: "À propos | Voice Up",
        description: "Curriculum et videos des projets musicaux de Tania Guillin.",
        canonHREF: "/tania"
    },
    conditions: {
        title: "Conditions | Voice Up",
        description: "Les conditions contractuelles des cours chez Voice Up.",
        canonHREF: "/conditions"
    },
    pricing: {
        title: "Tarifs | Voice Up",
        description: "Les différent plans tarifaires pour les sessions de chant chez Voice Up.",
        canonHREF: "/tarifs"
    },
    contact: {
        title: "Contact | Voice Up",
        description: "Formulaire de contact pour les sessions de chant à Voice Up.",
        canonHREF: "/contact"
    },
    enroll: {
        title: "Inscription | Voice Up",
        description: "Faire une demande d’inscription pour les cours de chant Voice Up.",
        canonHREF: "/inscription"
    },
} 